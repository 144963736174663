

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DetectChangesServices {
 


  private _detect_changes : boolean = false;
  private dataSubject = new BehaviorSubject<boolean>(this._detect_changes);
  private data$: Observable<boolean> = this.dataSubject.asObservable();


  constructor() { 

  }


  public detectChanges(){
    this.dataSubject.next(true);
  }

  public observable () : Observable<boolean> {
    return this.data$;
  }



}
