import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyRequestsComponent } from './my-requests/my-requests.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { MenuGroup } from '@core/interfaces/routes.interface';
import { OverviewComponent } from './overview/overview.component';


export const DASHBOARD  : MenuGroup = {
  title: 'My Dashboard',
  expanded: true,
  parent : 'panel',
  icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M128 136c0-22.1-17.9-40-40-40L40 96C17.9 96 0 113.9 0 136l0 48c0 22.1 17.9 40 40 40l48 0c22.1 0 40-17.9 40-40l0-48zm0 192c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40l48 0c22.1 0 40-17.9 40-40l0-48zm32-192l0 48c0 22.1 17.9 40 40 40l48 0c22.1 0 40-17.9 40-40l0-48c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40zM288 328c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40l48 0c22.1 0 40-17.9 40-40l0-48zm32-192l0 48c0 22.1 17.9 40 40 40l48 0c22.1 0 40-17.9 40-40l0-48c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40zM448 328c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40l48 0c22.1 0 40-17.9 40-40l0-48z"/></svg>`,
  items :  [
    {
      title : 'Overview',
      path: 'overview',
      component: OverviewComponent,
      data : {
        icon: `<svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <line x1="18" y1="20" x2="18" y2="10"></line>
                  <line x1="12" y1="20" x2="12" y2="4"></line>
                  <line x1="6" y1="20" x2="6" y2="14"></line>
                </svg>`
      }
    },
    {
      title : 'My requests',
      path: 'my-requests',
      component: MyRequestsComponent,
      data : {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/></svg>`
      }
    },
    {
      title : 'Favorites',
      path: 'favorites',
      component: FavoritesComponent,
      data : {
        icon : `<svg viewBox="0 0 24 24" width="20" height="20">
                                      <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" stroke="currentColor" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>`
      }
    },
    {
      title : 'Invoices',
      path: 'invoices', 
      redirectTo: 'invoices/', 
      pathMatch: 'full',
      data : {
        icon : `<svg viewBox="0 0 24 24" width="20" height="20">
                                      <path d="M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1-2-1z" stroke="currentColor" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>`
      }
    },
    // {
    //   title : 'Professional Setup',
    //   path : 'pro-setup',
    //   canActivate : [isBookedGuard],
    //   component: ProSetupComponent,
    //   data : {
    //     icon : 'user-tie',
    //     tooltip__off__can_active : 'Book with us to unlock this section.'
    //   }
    // }
  ]
}


const routes: Routes = [
 ...DASHBOARD.items,
  {
    title : 'Invoices',
    path: 'invoices/:invoice',
    component: InvoicesComponent,
  },
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),],
  exports: [RouterModule]
})
export class DashboardRouting {


}
