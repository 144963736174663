<div class="confirmation-container">
    <div class="status-icon">
      <div class="pulse-ring"></div>
      <div class="inner-circle">
        <svg style="width: 16px; height: 16px; fill: white;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160l0 8c0 13.3 10.7 24 24 24l400 0c13.3 0 24-10.7 24-24l0-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224l-64 0 0 196.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512l448 0c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1L448 224l-64 0 0 192-40 0 0-192-64 0 0 192-48 0 0-192-64 0 0 192-40 0 0-192zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
      </div>
    </div>
    <h2>Waiting for confirmation</h2>
    <p>Our agents have received the notification and are processing your request. We are verifying the availability of your accommodation.</p>
    
    <div class="progress-bar">
      <div class="progress"></div>
    </div>
    
    <div class="status-message">
      <div class="blinking-dot"></div>
      <div>Confirming transfer...</div>
    </div>
    
    <p class="info-text">You will receive an email notification once your reservation is confirmed.</p>
    
    <div class="guaranty-badge">
      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0da679" stroke-width="2"/>
        <path d="M9 12L11 14L15 10" stroke="#0da679" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span>MyOtherNest Guarantee</span>
    </div>
  </div>