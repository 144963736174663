import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { INFO_MYOTHERNEST } from '@data/const/myothernest.info';
import { Iinvoice } from '@core/interfaces/invoice.interface';
import { Rent } from '@core/classes/rent';
import { GLOBAL_FORMAT } from '@data/const/format-date';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-payment-done',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './payment-done.component.html',
  styleUrls: ['./payment-done.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDoneComponent {

  readonly address = INFO_MYOTHERNEST.office.address + ", "+INFO_MYOTHERNEST.office.county+" "+INFO_MYOTHERNEST.office.number + ", "+INFO_MYOTHERNEST.office.eircode +" "+INFO_MYOTHERNEST.office.country;
  readonly email = INFO_MYOTHERNEST.email;
  readonly contact_phone = INFO_MYOTHERNEST.phone_contact_us;

  @Input({required: true}) invoice !: Iinvoice;
  @Input() paying : boolean = false;

  constructor(protected auth : AuthService){}
  
  rent ?: Rent;
  ngOnChanges(){
    this.rent = new Rent(this.invoice.rent);
    const arrive = this.invoice.arrive;
    const depart = this.invoice.depart;
    this.rent.setCheckin(arrive,GLOBAL_FORMAT)
    this.rent.setCheckout(depart,GLOBAL_FORMAT)
  }
}
