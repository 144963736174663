import { Injectable, makeStateKey} from "@angular/core";
import { Irequest, Manager, Iresponse } from "@core/managers/Manager";

const requests_latest_length = makeStateKey<any>('requests_latest_length');

export interface Ivisitor {
  title : string,
  description : string,
  image : string,
  url : string
}

@Injectable({
  providedIn: 'root'
})
export class UserRequestManager extends Manager{


  private readonly path : string = "requests/user";

  last (response : Iresponse){
    const user  = this.user()
    if(!user) return;
    
    const request : Irequest = {
      url : `${this.path}/${user.id}/last`,
      key : requests_latest_length,
    }

    this._get(request,response);
  }

}
