import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class GuardDataService {
  private nextData: any;
  private errorData : any;
  
  setNextData(data: any) {
    this.nextData = data;
  }

  setErrorData(data : any){
    this.errorData = data;
  }
  
  getNextData() {
    return this.nextData;
  }

  getErrorData(){
    return this.errorData;
  }
  
  clearNextData() {
    this.nextData = null;
  }
}