import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReservationsComponent } from '@modules/my-properties/reservations/reservations.component';

@Component({
  selector: 'app-my-requests',
  standalone: true,
  imports: [ReservationsComponent],
  templateUrl: './my-requests.component.html',
  styleUrls: ['./my-requests.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyRequestsComponent {


  

}
