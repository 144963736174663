import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ChangeDetectorRef, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InvoicesManager } from '@core/managers/InvoicesManager';
import { InvoiceComponent } from "@components/invoice/invoice.component";
import { Iinvoice } from '@core/interfaces/invoice.interface';
import { PaymentGatewayComponent } from "@components/payment-gateway/payment-gateway.component";
import { CommonModule } from '@angular/common';
import { SEOService } from '@services/seo.service';
import { PaymentDoneComponent } from './payment-done/payment-done.component';
import { trigger, transition, style, animate } from '@angular/animations';
import { DetectChangesServices } from '@services/aside-dashboard/detect-changes.service';
import { GuardDataService } from '@services/welcome-hub/guard-data.guard';
import { ConfirmingInvoiceComponent } from './confirming-invoice/confirming-invoice.component';

@Component({
  selector: 'app-invoices',
  standalone: true,
  imports: [RouterModule,
    CommonModule,
    ConfirmingInvoiceComponent,
    MatIconModule, MatButtonModule, InvoiceComponent, PaymentGatewayComponent, PaymentDoneComponent],
  templateUrl: './invoices.component.html',
  styleUrls: ['../../../shared/styles/formfield-style.scss','../dashboard.component.scss','./invoices.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ]),
    trigger('scaleIn', [
      transition(':enter', [
        style({ transform: 'scale(0)' }),
        animate('300ms ease-out', style({ transform: 'scale(1)' }))
      ])
    ])
  ]
})
export class InvoicesComponent {




  payment_done : boolean = false;
  invoices : Iinvoice[] = [];
  invoice_to_pay ?: Iinvoice;
  request_id : string|null = null;
  confirming_payment : boolean = false;
  not_found : boolean = false;
  constructor(private invoicesManager : InvoicesManager,
    private seo : SEOService,
    private asideDetectChanges : DetectChangesServices,
    private guardWelcomeData : GuardDataService,
    private changes : ChangeDetectorRef,private route : ActivatedRoute, private router : Router){

      this.seo.setTitle('Dashboard - Invoices')
  }


  ngOnInit(){
    const invoice = this.route.snapshot.paramMap.get('invoice');
    this.request_id = invoice ? invoice : null;
    this.search();
  }

  loading : boolean = false;
  search (){
    const user = this.invoicesManager.user()!;
    this.loading = true;
    this.invoicesManager.getAll({user:user.id,request_book_id:this.request_id},{
      next: (response)=>{

        if(Array.isArray(response)){
          if(!this.request_id){
            this.invoices = response;
          }else{
            this.invoice_to_pay = response[0];
            if(this.invoice_to_pay?.status == 'paid'){
              // this.invoice_to_pay!.status = 'pending'
              this.paid();
            }else if(this.invoice_to_pay?.status == 'confirming'){
              this.confirming_payment = true;
            }
          }
        }


      },
      finalize: () => {
        this.loading = false;
        this.changes.detectChanges();
      }
    })
  }

  proceedToPay(event: Iinvoice) {
    this.invoice_to_pay = event;
  }

  paid() {
    this.payment_done = true;
    this.changes.detectChanges();
    this.guardWelcomeData.clearNextData();
    this.asideDetectChanges.detectChanges();
    this.scrollTop();
  }


  elementToScroll : any;
  scrollTop() {
    if(typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  confirming(event: boolean) {
    this.confirming_payment = event;
    if(this.invoice_to_pay?.status == 'confirming'){
      this.scrollTop();
    }
  }
}
