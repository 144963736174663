import { ChangeDetectionStrategy, ChangeDetectorRef, Component, makeStateKey } from '@angular/core';
import { FavoriteManager } from '@core/managers/FavoriteManager';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AllListingsComponent } from "@modules/home/find-a-home/all-listings/all-listings.component";
import { trigger, transition, style, animate } from '@angular/animations';
import { Rent } from '@core/classes/rent';
import { IRent } from '@core/interfaces/rent.interface';



@Component({
  selector: 'app-favorites',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, RouterModule, AllListingsComponent],
  templateUrl: './favorites.component.html',
  styleUrls: [ '../../../shared/styles/formfield-style.scss','../dashboard.component.scss','./favorites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
      trigger('fadeIn', [
        transition(':enter', [
          style({ opacity: 0, transform: 'translateY(20px)' }),
          animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
        ])
      ]),
      trigger('scaleIn', [
        transition(':enter', [
          style({ transform: 'scale(0)' }),
          animate('300ms ease-out', style({ transform: 'scale(1)' }))
        ])
      ])
    ]
})
export class FavoritesComponent { 


  loading : boolean = false;
  rents : Rent[] = [];
  constructor(private favoritesManager : FavoriteManager, private changes : ChangeDetectorRef){

  }

  ngOnInit(){
    this.loading = true;
    this.favoritesManager.get({
      next : (response)=>{
        this.rents = response.map((rent : IRent) => new Rent(rent));
      },
      finalize: () =>{
        this.loading = false;
        this.changes.detectChanges();
      }
    })
  }

}
