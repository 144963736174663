<!-- welcome.component.html -->
<div class="welcome-container">
    <!-- Payment Success Banner -->
    <div class="success-banner">
      <div class="success-content">
        <div class="success-icon">
          <mat-icon style="    width: auto;
          height: auto;
          font-size: 1.5em;
          display: flex
      ;
          justify-content: center;
          align-items: center;">task_alt</mat-icon>
        </div>
        <h2>Payment Successfully Completed</h2>
        <p style="max-width: 420px;">Your booking has been confirmed and secured. An invoice copy has been sent to your email address <strong>{{auth.getUser()?.email}}</strong></p>
        
        
        <div class="payment-details">
          <div class="detail-item">
            <p class="label">Reference ID</p>
            <p class="value">#{{invoice.request_book}}</p>
          </div>
          <div class="divider"></div>
          <div class="detail-item">
            <p class="label">Amount Paid</p>
            <p class="value">{{rent?.getSymbol()}}{{rent?.getPaymentDue()}}</p>
          </div>
          <div class="divider"></div>
          <div class="detail-item">
            <p class="label">Status</p>
            <p class="value status">paid</p>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Welcome Content -->
    <div class="welcome-header">
      <h1>WELCOME {{auth.getUser()?.name?.toUpperCase()}}</h1>
      <p>YOUR DUBLIN LIFE STARTS HERE!</p>
    </div>
    
    <h1 style="margin:1em 0;    text-align: center;
    font-size: 1.5em;">IMMEDIATE NEXT STEPS</h1>
    <!-- Timeline Steps -->
    <div class="timeline-grid">
      <!-- WhatsApp Coordination -->
      <div class="timeline-card">
        <div class="card-header">
          <div class="icon-circle">
            <mat-icon>schedule</mat-icon>
          </div>
          <div class="header-text">
            <p class="subtitle">1</p>
            <h3>Organizing Your Check-in <span style="color: var(--mon-green-light);">✓ OK</span></h3>
          </div>
        </div>
        <p class="card-content">
          Our representative will contact you shortly via WhatsApp to arrange your check-in time and answer any immediate questions you might have.
        </p>
      </div>
  
      <!-- Personal Check-in -->
      <div class="timeline-card">
        <div class="card-header">
          <div class="icon-circle">
            <mat-icon>home</mat-icon>
          </div>
          <div class="header-text">
            <p class="subtitle">2</p>
            <h3>Personalized Check-in <span style="color: var(--mon-green-light);">✓ OK</span></h3>
          </div>
        </div>
        <p class="card-content">
          One of our representatives will meet you at your new home where they will show you around the house and explain everything, hand over your keys, and ensure you're comfortably settled.
        </p>
      </div>
      
    </div>

    <h1 style="margin:1em 0;    text-align: center;
    font-size: 1.5em;">Our Help Doesn't Stop There!</h1>
    
    <div class="timeline-grid">
      

      <!-- Local Insights -->
      <div class="timeline-card">
        <div class="card-header">
          <div class="icon-circle">
            <mat-icon>place</mat-icon>
          </div>
          <div class="header-text">
            <p class="subtitle">3</p>
            <h3>Getting Settled</h3>
          </div>
        </div>
        <p class="card-content">
          We'll help you navigate the essential setup processes, including obtaining your PPSN and setting up an Irish bank account.
        </p>
      </div>
  
      <!-- Ireland Essentials -->
      <div class="timeline-card">
        <div class="card-header">
          <div class="icon-circle">
            <mat-icon>check_circle</mat-icon>
          </div>
          <div class="header-text">
            <p class="subtitle">4</p>
            <h3>Local Area Support</h3>
          </div>
        </div>
        <p class="card-content">
          We'll provide you with exclusive tips about your neighborhood, including the best local amenities, transport links, and essential services.
        </p>
      </div>

    </div>
  
    <!-- Contact Information -->
    <div class="contact-section">
      <div class="contact-content">
        <h2>Have questions or need assistance? Just ask!</h2>
        <p class="contact-subtitle">Our team is here to help you</p>
        <div class="contact-grid">
          <div class="contact-card">
            <p class="label">Office phone number</p>
            <p class="value">+35312314628</p>
          </div>
          <div class="contact-card">
            <p class="label">Office Address</p>
            <p class="value">{{address}}</p>
          </div>
          <div class="contact-card">
            <p class="label">WhatsApp Business number</p>
            <p class="value">{{contact_phone}}</p>
          </div>
          <div class="contact-card">
            <p class="label">Email</p>
            <p class="value">{{email}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>