import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-confirming-invoice',
  standalone: true,
  imports: [],
  templateUrl: './confirming-invoice.component.html',
  styleUrls: ['./confirming-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmingInvoiceComponent { }
