import { trigger, transition, style, animate } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { RequestManager } from '@core/managers/RequestManager';
import { UserManager } from '@core/managers/UserManager';
import { UserRequestManager } from '@core/managers/UserRequestManager';
import { ROUTES_NAME } from '@data/const/routes.data';
import { Utils } from '@utils/Utils';
import { SafeHTMLPipe } from "../../../services/safeHTML.pipe";


interface Section {
  visible: boolean;
  footer : string;
  title : string;
  url : string;
  count: number;
  icon :string;
  items: any[];
  queryParams ?: { [key: string] : string}
}

@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [RouterModule, SafeHTMLPipe],
  templateUrl: './overview.component.html',
  styleUrls: ['../../../shared/styles/formfield-style.scss','../dashboard.component.scss','./overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [
          transition(':enter', [
            style({ opacity: 0, transform: 'translateY(20px)' }),
            animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
          ])
        ]),
    trigger('scaleIn', [
      transition(':enter', [
        style({ transform: 'scale(0)' }),
        animate('300ms ease-out', style({ transform: 'scale(1)' }))
      ])
    ])
  ]
})
export class OverviewComponent {
  notificationCount = 3;
  userInitial = 'U';
  
  sections:{ [key: string]: Section }  = {
    requests: {
      title : "My requests",
      url : 'my-requests',
      footer : "View all my requests →",
      visible: true,
      queryParams : { tab: 'all' },
      count: 0,
      icon : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                      <polyline points="14 2 14 8 20 8"></polyline>
                      <line x1="16" y1="13" x2="8" y2="13"></line>
                      <line x1="16" y1="17" x2="8" y2="17"></line>
                      <polyline points="10 9 9 9 8 9"></polyline>
                    </svg>`,
      items: [
      ]
    },
    favorites: {
      title : "My favorites",
      url : 'favorites',
      footer : "View all my favorites →",
      visible: true,
      count: 0,
      icon : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                  </svg>`,
      items: [
      ]
    },
    invoices: {
      title : "My invoices",
      url : 'invoices',
      footer : "View all my invoices →",
      visible: true,
      icon :`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                    <line x1="1" y1="10" x2="23" y2="10"></line>
                  </svg>`,
      count: 0,
      items: [
      ]
    }
  };

  Utils = Utils

  protected _items : string[] = [];

  constructor(private router: Router, private requestManager : UserRequestManager, private changes : ChangeDetectorRef) {

    this._items = Object.keys(this.sections);

    this.requestManager.last({
      next : (response)=>{
        this.fill(response.requests,'requests');
        this.fill(response.favorites,'favorites');
        this.fill(response.invoices,'invoices');
        this.changes.detectChanges();
      }
    })

  } 

  fill(response : any, type: string){
    this.sections[type].count  = response?.length ?? 0;
    this.sections[type].items = response?.last ?? [];
  }


  ngOnInit(): void {
    // Aquí podrías cargar los datos desde un servicio
    this.loadDashboardData();
  }

  loadDashboardData(): void {
    // Simulación de carga de datos desde API
    // En un caso real, aquí utilizarías un servicio para obtener los datos
    console.log('Cargando datos del dashboard...');
  }

  ROUTES_NAME = ROUTES_NAME

 }
