<section class="main-section">
  
    <div class="section-container" @fadeIn>
      <div class="dashboard-header">
        <header class="header-content">
            <h1 class="page-title">Favorites</h1>
            <p class="subtitle">Manage and monitor all your favorites properties</p>
        </header>
      </div>
    
       @if(!loading){
            @if(rents.length){
                <div class="section-container" style="padding: 0 0.5em;">
                    <app-all-listings [rentals]="rents"></app-all-listings>
                </div>
            }@else {
            <!-- Empty state container -->
            <!-- empty-favorites.component.html -->
            <div class="empty-favorites" @fadeIn>
            
                <!-- Empty state container -->
                <div class="empty-state-container">
                <!-- Illustration -->
                <div class="illustration" @scaleIn>
                    <div class="icon-container">
                    <svg class="home-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/>
                        <polyline points="9 22 9 12 15 12 15 22"/>
                    </svg>
                    <svg class="heart-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"/>
                    </svg>
                    </div>
                </div>
            
                <!-- Message -->
                <div class="message">
                    <h2>No favorites yet</h2>
                    <p>Start saving your favorite properties by clicking the heart icon. 
                    They'll appear here for easy access.</p>
                </div>
            
                <!-- Suggestion cards -->
                <div class="suggestion-cards">
                    <div class="card">
                    <svg class="card-icon heart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"/>
                    </svg>
                    <p>Save properties you love</p>
                    </div>
                    <div class="card">
                    <svg class="card-icon home" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/>
                        <polyline points="9 22 9 12 15 12 15 22"/>
                    </svg>
                    <p>Compare your favorites</p>
                    </div>
                </div>
                </div>
            </div>
            }
       }
    </div>
</section>

