
    <section class="main-section">

        

        
      
      
        @if(invoice_to_pay){
          <div class="section-container" style="display: flex; flex-wrap: wrap; justify-content: center; align-items: center; max-width: 1250px;">
              <div class="steps-progress">
                  <div class="step active">
                      <div class="step-number">1</div>
                      <div class="step-label">Choose Home</div>
                  </div>
                  <div class="step active">
                      <div class="step-number">2</div>
                      <div class="step-label">Payment</div>
                  </div>
                  <div class="step" [ngClass]="{'active': payment_done , 'confirming' : confirming_payment && !payment_done}">
                      <div class="step-number">3</div>
                      <div class="step-label">Confirmation</div>
                  </div>
                  <div class="step" [ngClass]="{'active': payment_done}">
                      <div class="step-number">4</div>
                      <div class="step-label">Welcome</div>
                  </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; gap: 1em; padding: 1em; width: 100%; justify-content: center;">
                  @if(invoice_to_pay.status == 'pending'){
                    @if(!payment_done) {
                        <app-payment-gateway (paid)="$event ? paid() : null" (confirming)="confirming($event)" style="max-width: 500px;" [invoice]="invoice_to_pay"></app-payment-gateway>
                        <app-invoice class="remove-invoice-padding"  style="scale: 0.8; transform: translate(13%,-13%);" [invoice]="invoice_to_pay" [paying]="true"></app-invoice>
                    }@else {
                        <app-payment-done [invoice]="invoice_to_pay"></app-payment-done>
                    }
                  }@else {
                    @if(invoice_to_pay.status == 'confirming'){
                      <app-confirming-invoice style="max-width: 500px; height: fit-content;"></app-confirming-invoice>
                      <app-invoice class="remove-invoice-padding"  style="scale: 0.8; transform: translate(13%,-13%);" [invoice]="invoice_to_pay" [paying]="true"></app-invoice>
                    }@else if (invoice_to_pay.status == 'paid'){
                      <app-payment-done class="payment-invoice-paid" style="max-width: 500px; height: fit-content;" [invoice]="invoice_to_pay"></app-payment-done>
                      <app-invoice class="remove-invoice-padding"  style="scale: 0.8; transform: translate(13%,-13%);" [invoice]="invoice_to_pay" [paying]="true"></app-invoice>
                    }
                    @else {
                    <app-invoice [invoice]="invoice_to_pay"></app-invoice>
                    }
                  }
              </div> 
          </div>
          
        }@else   {
          <div  class="section-container" @fadeIn>
            <div class="dashboard-header">
              <header class="header-content">
                <h1 class="page-title">Invoices</h1>
                <p class="subtitle">Manage and monitor all your invoices</p>
              </header>
            </div>
        
            <div style="display: flex; flex-wrap: wrap; gap: 1em; width: 100%; justify-content: center;">
                @for(invoice of invoices ; track $index){
                    <app-invoice style="background: white;
          box-shadow: 0 4px 20px rgba(6, 6, 7, 0.1);" (proceed_to_pay)="proceedToPay($event)" [invoice]="invoice"></app-invoice>
                  }
              </div>
          </div>
        
        }
      
      
        @if(!invoice_to_pay && (!invoices ||!invoices.length) && !loading ){
            <div class="empty-state" @fadeIn>
                <div class="icon-wrapper">
                  <div class="alert-icon" @scaleIn>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="12" cy="12" r="10"/>
                      <line x1="12" y1="8" x2="12" y2="12"/>
                      <line x1="12" y1="16" x2="12.01" y2="16"/>
                    </svg>
                  </div>
                  <div class="main-icon">
                    <svg width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/>
                      <polyline points="14 2 14 8 20 8"/>
                      <line x1="16" y1="13" x2="8" y2="13"/>
                      <line x1="16" y1="17" x2="8" y2="17"/>
                      <line x1="10" y1="9" x2="8" y2="9"/>
                    </svg>
                  </div>
                </div>
              
                <h3 class="title">No invoices available for review</h3>
                
                <p class="description">
                  When new invoices are generated or require review, they will appear here.
                </p>

                <p class="description" style="font-size: small; margin-top: 1em;">
                    Check back later or refresh the page
                </p>
              </div>
        }
      
      </section>
      
