<section class="main-section" @fadeIn>

  <div  class="section-container" @fadeIn>

    <div class="dashboard-header">
      <header class="header-content">
        <h1 class="page-title">Overview</h1>
        <p class="subtitle">Quickly view and manage your requests, favorites, and invoices.</p>
        <p class="subtitle" style="font-size: small; margin-top: 1em;">
          Track requests, favorites, and check invoices.
        </p>
      </header>
    </div>





    <div class="dashboard-grid">
        
        @for(sect of _items ; track $index){
          <a class="card card-{{sections[sect].url}}" [routerLink]="[ROUTES_NAME.dashboard.parent+'/'+sections[sect].url]" [queryParams]="sections[sect].queryParams" [class.hidden]="!sections[sect].visible">
            <div class="card-header">
              <div class="card-title-area">
                <div class="card-icon-wrapper">
                  <div class="card-icon-bg"></div>
                  <div class="card-icon" [innerHTML]="sections[sect].icon | safeHTML">
                    
                  </div>
                </div>
                <h2 class="card-title">{{sections[sect].title}}</h2>
              </div>
              <div class="card-count">{{ sections[sect].count }}</div>
            </div>
            <div class="card-content">
              @if(sect == 'requests'){
                @for (item of sections[sect].items; track $index) {
                  <a [routerLink]="[ROUTES_NAME.dashboard.parent+'/'+sections[sect].url]" [queryParams]="{ search: item.id }" class="list-item">
                    <div>
                      <div class="list-title">{{ item.rent.title }}</div>
                      <div class="list-subtitle">{{ item.subtitle }}</div>
                    </div>
                    @if (item.status) {
                      <span class="status {{ item.status }}">{{ Utils.capitalize(item.status) }}</span>
                    }
                  </a>
                }
              }@else if(sect == 'favorites'){
                @for (item of sections[sect].items; track $index) {
                  <div class="list-item">
                    <div>
                      <div class="list-title">{{ item.title }}</div>
                      <div class="list-subtitle">{{ item.subtitle }}</div>
                    </div>
                    @if (item.price.money) {
                      <div class="list-value">{{item.price.currency.symbol}}{{ item.price.money }}</div>
                    }
                  </div>
                }
              }@else if(sect == 'invoices'){
                @for (item of sections[sect].items; track $index) {
                  <a [routerLink]="[ROUTES_NAME.dashboard.parent+'/'+sections[sect].url,item.request_book]"  class="list-item">
                    <div>
                      <div class="list-title">{{ item.request_book__rent.title }}</div>
                      <div class="list-subtitle">{{ item.subtitle }}</div>
                    </div>
                    @if (item.rent.price.reservation) {
                      <div class="list-value">
                        @if (item.status) {
                          <span class="status {{ item.status }}">{{ Utils.capitalize(item.status) }}</span>
                        }
                        {{item.rent.price.currency.symbol}}{{ item.rent.price.reservation }}
                      </div>
                    }@else if (item.rent.price.price) {
                      <div class="list-value">
                        @if (item.status) {
                          <span class="status {{ item.status }}">{{ Utils.capitalize(item.status) }}</span>
                        }
                        {{item.rent.price.currency.symbol}}{{ item.rent.price.price }}
                      </div>
                    }
                    
                    
                  </a>
                }
              }
            </div>
            <div class="card-footer">{{sections[sect].footer}}</div>
          </a>
        }

        
        
    </div>
  </div>
    


</section>